import { Controller } from 'stimulus'

export default class extends Controller {
  public connect(): void {
    if (this.alert) {
      M.toast({html: this.alert, classes: 'alert'})
    }
    if (this.notice) {
      M.toast({html: this.notice, classes: 'notice'})
    }
  }

  private get alert(): string | undefined {
    return this.data.get('alert') as string | undefined
  }

  private get notice(): string | undefined {
    return this.data.get('notice') as string | undefined
  }
}
