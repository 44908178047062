import { Controller } from 'stimulus'

export default class extends Controller {
  public static targets: string[] = [ 'body', 'icon']

  public toggle(): void {
    const hidden = this.body.classList.contains('hidden')
    hidden ? this.expand() : this.collapse()
  }

  private expand(): void {
    this.body.classList.remove('hidden')
    this.icon.innerHTML = 'expand_less'
  }

  private collapse(): void {
    this.body.classList.add('hidden')
    this.icon.innerHTML = 'expand_more'
  }

  private get body(): HTMLDivElement {
    return this.targets.find('body') as HTMLDivElement
  }

  private get icon(): HTMLElement {
    return this.targets.find('icon') as HTMLElement
  }
}
