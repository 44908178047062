import { Controller } from 'stimulus'

export default class extends Controller {
  public static targets: string[] = [ 'continue', 'checkbox']

  public connect(): void {
    if (this.first_invalid_question_id) {
      this.scrollToId(this.first_invalid_question_id)
    }
  }

  private scrollToId(id: string): void {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }

  private get first_invalid_question_id(): string | null {
    return this.data.get('first-invalid-question-id')
  }
}
