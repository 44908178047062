import * as Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  public static targets: string[] = [ 'input', 'form']

  public search(): void {
    this.submit()
  }

  public clearSearch(): void {
    this.input.value = ''
    this.submit()
  }

  private submit(): void {
    Rails.fire(this.form, 'submit')
  }

  private get input(): HTMLInputElement {
    return this.targets.find('input') as HTMLInputElement
  }

  private get form(): HTMLFormElement {
    return this.targets.find('form') as HTMLFormElement
  }
}
