// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start() // TODO: Active Storage not in use
require('materialize-css')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import { Application } from 'stimulus'
// tslint:disable-next-line
import { definitionsFromContext } from 'stimulus/webpack-helpers'
require('../result')

const application = Application.start()
// tslint:disable-next-line
const context = require.context('../controllers', true, /\.ts$/)
application.load(definitionsFromContext(context))

// https://www.honeybadger.io/blog/turbolinks/
const initializeMaterialize = () => {
  M.updateTextFields()

  M.Sidenav.init(document.querySelectorAll('.sidenav'), {})
  M.TapTarget.init(document.querySelectorAll('.tap-target'))
  M.Collapsible.init(document.querySelectorAll('#customer-orders'), {
    accordion: false,
  })
  M.Collapsible.init(document.querySelectorAll('#customer-profile'), {})

  M.FormSelect.init(document.querySelectorAll('select'), {})

  M.Modal.init(document.querySelectorAll('[id^="order-continue-modal"]'), {
    dismissible: true,
  })

  M.Tooltip.init(document.querySelectorAll('.tooltipped'), {})
  M.Tabs.init(document.querySelectorAll('#customers-tabs'))
  M.Tabs.init(document.querySelectorAll('#orders-tabs'))
}

const cleanupMaterialize = () => {
  Array.from(document.querySelectorAll('.sidenav')).forEach((element) => {
    const instance = M.Sidenav.getInstance(element)
    if (instance) {
      instance.destroy()
    }
  })
  Array.from(document.querySelectorAll('select')).forEach((element) => {
    const instance = M.FormSelect.getInstance(element)
    if (instance) {
      instance.destroy()
    }
  })

  Array.from(document.querySelectorAll('.tap-target')).forEach((element) => {
    const instance = M.TapTarget.getInstance(element)
    if (instance) {
      instance.destroy()
    }
  })
  Array.from(document.querySelectorAll('.tabs')).forEach((element) => {
    const instance = M.Tabs.getInstance(element)
    if (instance) {
      instance.destroy()
    }
  })
  Array.from(document.querySelectorAll('.tooltipped')).forEach((element) => {
    const instance = M.Tooltip.getInstance(element)
    if (instance) {
      instance.destroy()
    }
  })
  Array.from(document.querySelectorAll('.sidenav')).forEach((element) => {
    const instance = M.Sidenav.getInstance(element)
    if (instance) {
      instance.destroy()
    }
  })
  Array.from(document.querySelectorAll('.modal')).forEach((element) => {
    const instance = M.Modal.getInstance(element)
    if (instance) {
      instance.destroy()
    }
  })
  Array.from(document.querySelectorAll('.collapsible')).forEach((element) => {
    const instance = M.Collapsible.getInstance(element)
    if (instance) {
      instance.destroy()
    }
  })

}

document.addEventListener('turbolinks:load', (_) => {
  initializeMaterialize()
}, { once: true })

document.addEventListener('turbolinks:render', (_) => {
  initializeMaterialize()
})

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('agreement_expiration_notice')) {
    // Only open the Feature-Discovery once per session
    if (sessionStorage) {
      if (!sessionStorage.getItem('expirationNoticeDisplayed')) {
        const element = document.getElementById('agreement_expiration_notice')
        if (element) {
          M.TapTarget.getInstance(element).open()
          sessionStorage.setItem('expirationNoticeDisplayed', 'true')
        }
      }
    } else {
      // If session storage is not availabe, show the Feature-Discovery each time
      const element = document.getElementById('agreement_expiration_notice')
      if (element) {
        M.TapTarget.getInstance(element).open()
      }
    }
  }
})

document.addEventListener('turbolinks:before-render', (_) => {
  cleanupMaterialize()
})
