import { Controller } from 'stimulus'

export default class extends Controller {
  public static targets: string[] = ['source', 'destination', 'sample_number']
  public DECIMAL_PLACES: number = 3
  private NUMBER_OF_REFERENCES: number = 2

  public connect(): void {
    this.updateDestinations()
  }

  public update(): void {
    this.updateDestinations()
  }

  private updateDestinations(): void {
    const correctionFactor = parseFloat(this.source.value)
    this.destination.forEach((destination) => {
      destination.value = correctionFactor.toFixed(this.DECIMAL_PLACES).toString()
    })
    this.sampleNumbers
      .map((elem) => elem.value)
      .forEach((sample) => {
        const value_telos = parseFloat(this.inputTelos(sample).value)
        const value_36b4 = parseFloat(this.input36b4(sample).value)
        const kbp = Math.log10(value_telos / value_36b4) * correctionFactor
        this.inputKbp(sample).value = kbp.toFixed(this.DECIMAL_PLACES).toString()
      })
    for (let refIndex = 1; refIndex <= this.NUMBER_OF_REFERENCES; refIndex++) {
      const actualKbp = parseFloat(this.referenceActualKbp(refIndex).innerText)
      const referenceKbp = parseFloat(this.referenceReferenceKbp(refIndex).innerText)
      const correctedKbp = (actualKbp * correctionFactor)
      const difference = referenceKbp - correctedKbp
      this.referenceCorrectedKbp(refIndex).innerText = correctedKbp.toFixed(this.DECIMAL_PLACES).toString()
      this.referenceDifference(refIndex).innerText = difference.toFixed(this.DECIMAL_PLACES).toString()
    }
  }

  private get source(): HTMLInputElement {
    return this.targets.find('source') as HTMLInputElement
  }

  private get destination(): HTMLInputElement[] {
    return this.targets.findAll('destination') as HTMLInputElement[]
  }

  private get sampleNumbers(): HTMLInputElement[] {
    return this.targets.findAll('sample_number') as HTMLInputElement[]
  }

  private inputTelos(sample: string): HTMLInputElement {
    return this.targets.find('quantity_mean_telos_' + sample) as HTMLInputElement
  }

  private input36b4(sample: string): HTMLInputElement {
    return this.targets.find('quantity_mean_36b4_' + sample) as HTMLInputElement
  }

  private inputKbp(sample: string): HTMLInputElement {
    return this.targets.find('kbp_' + sample) as HTMLInputElement
  }

  private referenceActualKbp(index: number): HTMLElement {
    return this.targets.find('reference_actual_kbp' + index.toString()) as HTMLElement
  }
  private referenceCorrectedKbp(index: number): HTMLElement {
    return this.targets.find('reference_corrected_kbp' + index.toString()) as HTMLElement
  }
  private referenceReferenceKbp(index: number): HTMLElement {
    return this.targets.find('reference_reference_kbp' + index.toString()) as HTMLElement
  }
  private referenceDifference(index: number): HTMLElement {
    return this.targets.find('reference_difference' + index.toString()) as HTMLElement
  }
}
