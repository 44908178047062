import * as Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  public static targets: string[] = ['answer', 'answers']
  public change(event: Event): void {
    this.removeInvalidClass()
    this.updateAnswers(event)
    if (this.autosave) {
      this.submitForm()
    }
  }

  private updateAnswers(event: Event): void {
    if (this.answerKeyFromEvent(event) === 'never') {
      // If the "Never" answer was checked de-select all other answers
      this.answers.filter((answer) => this.answerKey(answer) !== 'never')
        .forEach((answer: HTMLInputElement) => {
          answer.checked = false
        })
    } else {
      // If any other answer than "Never" was checked, de-select the "Never" answer
      this.answers.filter((answer) => this.answerKey(answer) === 'never')
        .forEach((answer: HTMLInputElement) => {
          answer.checked = false
        })
    }
  }

  private removeInvalidClass(): void {
    this.element.classList.remove('invalid')
  }

  private submitForm(): void {
    const form = this.element.closest('form')!
    // This is a little bit of a hack
    // Actually, we want this form not to be remote so that the validation works (invalid classes rendered by the html.erb questions)
    // Only for autosave do we want to submit the form with remote: true
    // Attention: Its important that the form initially has `remote: false`!
    form.dataset.remote = 'true'
    Rails.fire(form, 'submit')
    form.dataset.remote = 'false'
  }

  private get autosave(): boolean {
    return (this.data.get('autosave') === 'true') || false
  }

  private get answers(): HTMLElement[] {
    return this.targets.findAll('answer') as HTMLElement[]
  }

  private answerKeyFromEvent(event: Event): string | undefined {
    if (event.target && 'dataset' in event.target) {
      return (event.target as HTMLElement).dataset.questionAnswer
    }
    return undefined
  }

  private answerKey(element: any): string | undefined {
    if ('dataset' in element) {
      return (element as HTMLElement).dataset.questionAnswer
    }
    return undefined
  }
}
