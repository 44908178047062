import { Autocomplete, AutocompleteData } from 'materialize-css'
import { Controller } from 'stimulus'

export default class extends Controller {
  public static targets: string[] = ['input']
  public static values: any = {
    search: Object,
  }
  private readonly inputTarget: HTMLInputElement
  private readonly searchValue: AutocompleteData
  private autocomplete: Autocomplete

  public connect(): void {
    this.autocomplete = Autocomplete.init(this.inputTarget, {
      data: this.searchValue,
    })
  }

  public disconnect(): void {
    this.autocomplete.destroy()
  }
}
