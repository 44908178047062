import { Controller } from 'stimulus'

export default class extends Controller {
  public static targets: string[] = [ 'continue', 'checkbox']
  private readonly continueTargets: HTMLLinkElement[]
  private readonly checkboxTarget: HTMLInputElement
  private modal: M.Modal

  public connect(): void {
    this.modal = M.Modal.init(this.element, {
      dismissible: false,
    })

    if (this.present) {
      this.modal.open()
    }
    this.update()
  }

  public disconnect(): void {
    this.modal.destroy()
  }

  public change(): void {
    this.update()
  }

  private update(): void {
    this.continueTargets.forEach((target) => {
      target.disabled = !this.checkboxTarget.checked
    })
  }

  private get present(): boolean {
    return (this.data.get('present') === 'true')
  }
}
