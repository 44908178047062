import { init } from '@sentry/browser'
import { Controller } from 'stimulus'

export default class extends Controller {
  public connect(): void {
    if (this.dsn) {
      init({
        dsn: this.dsn,
      })
    }
  }

  private get dsn(): string | undefined {
    return this.data.get('dsn') as string | undefined
  }
 }
